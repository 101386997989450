import { Box, IconButton, useTheme, Tooltip } from "@mui/material"
import { required, SimpleForm, useNotify, useTranslate, SaveButton, Toolbar } from "react-admin";
import StandardDialog from "../dialog/StandardDialog";
import { useState } from "react";
import { exportVouchers } from "../../rest/restClient";
import styledFormInputs from "../inputs/styledFormInputs";




const validateRequired = [required('Champs obligatoire')]


const Icon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-upload">
            <path stroke="none" d="M0 0h18v18H0z" fill="none" strokeWidth="0.75" />
            <path d="M3 12.75v1.5a1.5 1.5 0 0 0 1.5 1.5h9a1.5 1.5 0 0 0 1.5 -1.5v-1.5" />
            <path d="m5.25 6.75 3.75 -3.75 3.75 3.75" />
            <path d="M9 3v9" />
        </svg>

    )
}


const DialogComponent = ({ setOpenDialog }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const inputsData = [
        { name: 'batchNumber', label: translate('resources.vouchers.fields.batch_number'), validate: validateRequired, type: "number" },
        { name: 'email', label: translate('resources.notifications.email'), validate: validateRequired, type: "text" },
    ]

    return (
        <Box>
            <SimpleForm
                toolbar={
                    <Toolbar sx={{
                        background: "none"
                    }}>
                        <SaveButton label={translate('resources.export.create')} />
                    </Toolbar>

                }

                onSubmit={({ batchNumber, email }) => {
                    exportVouchers({ batchNumber, email })
                        .then(() => {
                            notify(translate('resources.export.export_starting'), { messageArgs: { smart_count: 1 } });
                            setOpenDialog(false)
                        })
                        .catch(response => {
                            notify(
                                `${translate('resources.internal_error')}: ${response.body.description} (${response.status
                                })`,
                                'warning'
                            );
                        })
                }

                }
            >


                {styledFormInputs(inputsData)}
            </SimpleForm>

        </Box>

    )
}
export const ExportVouchersButton = ({ }) => {
    const theme = useTheme();
    const translate = useTranslate();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <Box sx={{
            padding: "0px",
            marginRight: "20px",
        }}>

            <Tooltip enterDelay={300} title={translate('resources.locations.labels.exportData')} >
                <IconButton
                    sx={{
                        padding: "4px",
                    }}
                    onClick={() => {
                        setOpenDialog(true);
                    }}
                >
                    <Icon color={theme.palette.primary.dark} />
                </IconButton>
            </Tooltip>

            <StandardDialog
                open={openDialog}
                onClose={() => { setOpenDialog(false) }}
                fullWidth={false}
                width="auto"
                BodyComponent={() => <DialogComponent setOpenDialog={setOpenDialog} />}

            />
        </Box>

    )


}