import { Filter, Datagrid, useTranslate, FunctionField, List, TextInput, Pagination, ReferenceField, TextField, useListContext, useLocaleState, ReferenceInput, AutocompleteInput, NumberInput } from 'react-admin'
import { autocompleteInputCommonStyle, datagridCommonStyle, listCommonStyle, searchInputCommonStyle } from '../../../themes/commonStyles'
import { LIST_TYPE_TABLE } from '../../../ui/button/ListTypeButton';
import { useTheme, Box, Typography } from '@mui/material';
import { isROAdmin, isSuperAdmin } from '../../../helper/resourceHelper'
import { AddBtnWithOptionalMenu } from '../../../ui/button/AddBtn';
import SearchInputLabelComponent from '../../../ui/inputs/SearchInputLabelComponent';
import CustomChip from '../../../ui/CustomChip';
import CodeButton from '../../../ui/button/CodeButton';
import LoadingDialog from '../../../ui/dialog/LoadingDialog';
import { ExportVouchersButton } from '../../../ui/button/ExportVouchersButton';









const VoucherListActions = () => {
    const { resource } = useListContext();
    const translate = useTranslate();
    const addBtnMenuItems = []


    if (isSuperAdmin() || isROAdmin()) {
        addBtnMenuItems.push({
            label: translate('resources.vouchers.create'),
            path: `/${resource}/create`
        })
    }

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "row"

        }}>
            <ExportVouchersButton />
            <AddBtnWithOptionalMenu menuItems={addBtnMenuItems} isMobile={false} />
        </Box>
    )






}



const VoucherListPagination = ({ }) => {

    return (<Pagination sx={{
        '& .MuiToolbar-root': {
            borderTopRightRadius: "0px",
            borderTopLeftRadius: "0px",
            borderBottomRightRadius: "12px",
            borderBottomLeftRadius: "12px",
            marginTop: "0px",

        }

    }} rowsPerPageOptions={[10, 25, 50, 100, 500]} />)
};

const LoadingPopUp = ({ }) => {
    const { isPending, isFetching, isLoading } = useListContext();
    return (
        <LoadingDialog
            isPending={isPending}
            isFetching={isFetching}
            isLoading={isLoading}
        />
    )

};

const VoucherList = props => {
    const [locale, setLocale] = useLocaleState();
    const theme = useTheme();
    const translate = useTranslate();

    return (
        <>
            <Typography sx={{ color: theme.palette.text.primary, textAlign: 'start', fontWeight: 700, fontSize: "24px", lineHeight: "42px", margin: "0px 0px 16px 0px" }}>
                {translate('resources.vouchers.vouchers')}
            </Typography>
            <List
                actions={<VoucherListActions />}
                filter={{ includes: 'plan', language: locale ? locale : "en" }}
                filters={
                    <Filter  {...props}>
                        <TextInput
                            id="outlined-basic-size-small"
                            label={<SearchInputLabelComponent />}
                            source="q"
                            alwaysOn
                            sx={searchInputCommonStyle({ theme })}
                        />
                        <NumberInput
                            id="outlined-basic-size-small"
                            label="batch_number"
                            source="batch_number"
                            alwaysOn
                            sx={searchInputCommonStyle({ theme })}
                        />
                        {isSuperAdmin() && <ReferenceInput source="root_organization_id" reference="root_organizations" alwaysOn >
                            <AutocompleteInput
                                id="outlined-basic-size-small"
                                disablePortal
                                optionText="name"
                                matchSuggestion={() => true} label="resources.vouchers.fields.root_organization"
                                sx={autocompleteInputCommonStyle({ theme })}
                            />
                        </ReferenceInput>}
                    </Filter>

                }
                sx={listCommonStyle({ isLargeOrLess: false, listFormat: LIST_TYPE_TABLE, theme })}
                perPage={25}
                pagination={false}
            >

                <Datagrid
                    bulkActionButtons={false}
                    size='small'
                    sx={datagridCommonStyle({ theme })}
                >

                    <FunctionField
                        label={translate('resources.vouchers.fields.code')}
                        render={record => record.code ?
                            <CodeButton value={record.code} /> : null}
                        sortable={false}
                    />
                    <TextField source="serial_number" />
                    <TextField source="batch_number" />
                    <FunctionField
                        label={translate('resources.vouchers.fields.activated')}
                        render={record => (record.activated === true || record.activated === false) ?
                            <CustomChip label={translate(`resources.vouchers.activated.${record.activated}`)} value={record.activated ? "success" : "pending"} /> : null}
                        sortable={false}
                    />

                    <ReferenceField source="root_organization_id" reference="root_organizations" allowEmpty>
                        <FunctionField
                            render={record => record.name ? record.name.toUpperCase() : record.id}
                            sortable={false}
                        />
                    </ReferenceField>
                    <ReferenceField label={translate('resources.vouchers.fields.plan')} source="plan.plan_id" reference="device_sub_plans" allowEmpty>
                        <FunctionField
                            render={record => record.title ? record.title.toUpperCase() : record.id}
                            sortable={false}
                        />
                    </ReferenceField>
                </Datagrid>
                <VoucherListPagination />
                <LoadingPopUp />
            </List>
        </>
    )

}


export default VoucherList;