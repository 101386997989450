import React, { useState } from 'react'
import { Button } from '@mui/material'









const CodeButton = ({ value }) => {
    const [show, setShow] = useState(false)

    if (!value) return null

    return (
        <Button
            onClick={() => {
                setShow(true)
                setTimeout(() => {
                    setShow(false)
                }, 5000);
            }}
            sx={{
                height: "24px",
                minWidth: "55px",
                fontSize: "12px",
                fontWeight: 400,
                color: 'text.dark'
            }}
        >
            {show ? value :
                `${value.substring(0, 2)}****`
            }
        </Button>
    )


}


export default CodeButton

