import defaultMessages from 'ra-language-english'

export default {
    ...defaultMessages,
    resources: {
        about: 'About',
        month: 'month',
        year: 'year',
        for: 'for',
        per: 'per',
        heading_messages: {
            message: "Message",
            verify_notification_destinations: "You haven't yet verified the sms or emails linked to your alerts, click here to verify them",
            enter_code: "Verification",
            verify: "Verify",
            verified: "Verified",
            error_verifying: "Error verifying",
            error_resending_code: "Error sending a new code",
            invalid_code: "Invalid code",
            resend_code: "Resend code",
            code_resent: "Code resent!",
            code_ask: "Code ?",
            resending_code_limit_reached: "You have reached the maximum number of verification retries",
            remaing_credit: "Available credit",
            sms: "SMS",
            add_credit: "Add Credit",
            device_name: "Device name:",
            device_id: "Device ID:",
        },
        common: {
            errorOccured: 'An error has occured',
            name: "Name",
            id: "ID",
            account: 'Account',
            search: 'Search',
            a_search: 'Search',
            tools: 'Tools',
            actions: "Actions",
            optional: "Optional",
            cancel: 'Cancel',
            clear: 'Clear',
            ok: 'Ok',
            history: 'History',
            help: 'Aide',
            documentation: 'Documentation',
            contact: "Contact",
            developers: 'Developers',
            delete_history: 'Delete device history',
            delete_history_paragraph: 'Are you sure  ? All locations, measures will be deleted. This action is permanent.',
            update_success: "Successful update",
            next: "Next",
            back: 'Back',
            home_redirect: 'You will be redirected to home page...',
            filters: "Filters",
            filter: "Filter",
            dark_mode: "Dark mode",
            light_mode: "Light mode",
            language: "Language",
            i_confirm: "I confirm",
            loading_dialog_title: "Loading...",
            loading_dialog_content: "Please wait while we synchronize your data",
            connecting_dialog_title: "Connecting...",
            apply_filters: "Apply",
            reset_filters: "Reset",
            view_more: "view more",
            do_not_show_this_message_again: "Do not show this message again",
            favorite_filters: "Favorite searches"
        },
        errors: {
            "file_is_larger_than_500_KB": "Error: File is larger than 500 Kilo bytes",
            "file_is_larger_than_200_KB": "Error: File is larger than 200 Kilo bytes",
            "file_is_larger_than_2_MB": "Error: File is larger than 2 Mega bytes",
            "file_is_larger_than_5_MB": "Error: File is larger than 5 Mega bytes",
            "file_is_larger_than_1_MB": "Error: File is larger than 1 Mega bytes",
        },
        sidebar: {
            device_sub_plans: "Device Plans",
            sms_credit_plans: "Sms Credit Plans",
            device_sub: "Device Subscriptions",
            vouchers: "Vouchers",
            sections: {
                dashboard: "Dashboard",
                devices: "Devices",
                data: "Data",
                notifications: "Notifications",
                members: "Members",
                developers: "Developers",
                assistance: "Assistance",
                account: "Account",
                subscriptions: "Subscriptions",
                website_info: "Information",
                settings: "Settings"
            }
        },
        configuration: {
            name: 'Configuration',
            general: 'General',
        },
        data_mode: {
            card_mode: "CARDS MODE",
            table_mode: "TABLE MODE"
        },
        dashboard: {
            name: 'Dashboard',
            active: "Actives",
            unpaid: "Unpaid",
            titles: {
                device_subscriptions_summary: "My subscriptions",
                number_of_devices_in_account: "My devices",
                number_of_devices_in_account_by_products: "My devices by product",
                sms_credit_summary: "My sms credit",
                locations_summary: "My devices locations",
                number_of_nack_alerts: "Non acknowledged alerts",
                number_of_alerts: "My created alerts",
                number_of_stationary_devices: "My stationary devices",
                number_of_devices_outside_organization_boundary: "My devices outside the perimeter",
            },
            number_of_devices_outside_organization_boundary: {
                organization: "Organization",
                organization_address: "Site address",
                boundary_radius: "Boundary radius",
                number_of_devices: "Number of devices",
                created_at: "Data updated at:",
                search_text: "My devices outside the perimeter of the organization %{organizationName}"
            },
            number_of_stationary_devices: {
                for_more_than_10_days: "For more than 10 days",
                for_more_than_20_days: "For more than 20 days",
                for_more_than_30_days: "For more than 30 days",
                for_more_than_50_days: "For more than 50 days",
                for_more_than_70_days: "For more than 70 days",
                created_at: "Data updated at:",
                notice: "A device is considered stationary as long as it has not moved more than 100 meters.",
                devices_list_subtitles: {
                    for_more_than_10_days: "My stationary devices for more than 10 days",
                    for_more_than_20_days: "My stationary devices for more than 20 days",
                    for_more_than_30_days: "My stationary devices for more than 30 days",
                    for_more_than_50_days: "My stationary devices for more than 50 days",
                    for_more_than_70_days: "My stationary devices for more than 70 days",
                }

            },
            number_of_devices_in_account: {
                total_devices: "Total",
                total_active_devices: "Total active",
                total_inactive_devices: "Total inactive",
                created_at: "Data updated at:"

            },
            number_of_alerts: {
                nbr_of_alerts: "Total created",
                nbr_of_email_alerts: "Total email alerts",
                nbr_of_sms_alerts: "Total sms alerts",
            },
            sms_credit_summary: {
                nbr_of_remaining_credit: "Remaining credit"
            }

        },
        addresses: {
            name: "Saved addresses",
            radius_meters: "Radius in meters",
            latitude: "Latitude",
            longitude: "Longitude",
        },
        ui_setup: {
            name: "Display preferences",
            dashboard: "Dashboard"

        },
        devices: {
            page_titles: {
                my_devices: "My devices",
                add_device: "Add device",
                add_devices_bulk: "Add bulk devices",
                edit_device: "Edit device configuration"
            },
            add_bulk_device: 'Add bulk devices',
            add_new_device: 'Add device',
            add_new_device_succes: 'Device Added',
            device_image_deleted_success: 'Device image deleted',
            device_image_delete_confirmation: 'Are you sure you want to delete your device image?',
            device_sub_plans: "Device subscription plans",
            technology: 'Technology',
            activate_new_device: 'Activate new device',
            activate: 'Activate',
            advsearch: 'Advanced search',
            choose_advsearch_option: "Please select the advanced search option below",
            go_tracking_mode: "Switch to live tracking mode",
            go_tracking_mode_because_pending_more_than_24_jours: "The LIVE mode request has not been taken into account by the device in the last 24 hours. You can now try again.",
            tracking_mode_info_title: "How does it work?",
            tracking_mode_info_1: "By activating LIVE mode, the device will switch to 1 position every minute in case of movement, or 1 position every 10 minutes otherwise, for 30 minutes,",
            tracking_mode_info_2: "At the end of the 30 minutes, the device returns to the mode corresponding to its PLAN (Basic / Regular / Tracking).",
            tracking_mode_info_3: "LIVE mode is free of charge and does not require a change of subscription.",
            tracking_mode_info_4: "Please note that the LIVE mode activation time will depend on the Plan that has been activated for the device:",
            tracking_mode_info_5: "- 24 hours for the Basic plan",
            tracking_mode_info_6: "- 1h for the Regular plan",
            tracking_mode_info_7: "- 10min for the Tracking plan",
            last_tracking_mode_entry: "Last tracking mode entry",
            success_setting_on_tracking_mode: "Live tracking mode request sent successfully",
            error_setting_on_tracking_mode: "An error accured when setting on live tracking mode",
            recovery_mode_status: "Status",
            last_recovery_mode_request: "Date of last request",
            last_recovery_mode_entry: "Date of last LIVE mode entry",
            last_recovery_mode_exit: "Date of last exit from LIVE mode",
            already_attached: 'Device already attached',
            attaching: "The beacon is being attached to your account...",
            attached: 'Device Attached',
            attach: "Activate device",
            error_attachement_checkout: "An error occurred while attaching your subscription!  You will be redirected to the support contact page... ",
            available: 'Device available',
            battery_status: {
                bad: 'Bad',
                good: 'Good',
            },
            product: 'Product',
            type: "Type",
            configuration: 'Device Settings',
            create: 'Add a new device',
            delete: 'Detach',
            device_id: 'Device identifier',
            device_name: 'Device Name',
            enter_device_id: 'Please, enter your device identifier',
            enter_device_id_instruction: `Please, enter the device id written on the back of your device`,
            enter_voucher: 'Enter the code written on your voucher',
            error_attachement: 'Error Device Attachement: ',
            fastsearch: 'Quick search',
            edit_title: 'Settings %{deviceName}',
            fields: {
                name: 'Name',
                mac_address: '#ID',
                serial_number: '#ID (serial_number)',
                battery: 'Battery',
                software_version: 'Firmware',
                secondary_mac_address: 'Secondary mac address',
                minor: 'Minor',
                major: 'Major',
                proximity_uuid: 'Proximity UUID',
                ibeacon_ids_file: 'Ids file (columns: mac_address,proximity_uuid,minor,major,secondary_mac_address)',
                unb_ids_file: 'Ids file (columns: serial_number,secondary_mac_address)',
                last_subscription: {
                    status: 'Subscription',
                    start_date: 'Start date',
                    end_date: 'End date',
                },
                image: 'Image',
                last_seen: 'Last transmission',
                owner: 'Owner',
                organization: 'Organization',
                actions: 'Actions',
                tags: "Tags"
            },
            edit_sections: {
                general: "General",
                configuration: "Configuration"
            },
            config: {
                geolocation_period: 'Geolocation frequency (minutes)',
                retry_period: 'Geolocation retry (minutes)',
                wifi_sniffing: 'Wifi sniffing',
                bluetooth_advertising: 'Bluetooth advertising',
                bluetooth_advertising_frequency: 'Bluetooth advertising frequency (secondes)',
                temperature_low_limit: 'Low temperature threshold',
                temperature_up_limit: 'Up temperature threshold',
                humidity_low_limit: 'Low humidity threshold',
                humidity_up_limit: 'Up humidity threshold',
                send_message_in_motion: 'Send messages in motion',
                co2: {
                    frequencies: "Frequencies :",
                    plateform: "Plateform :",
                    plateformFrequency: "Plateform frequency",
                    plateformCaption: "Frequency of data sending to the platform (Default = 30 min)",
                    sensor: "Sensor :",
                    extra_measurement_count: "Number of extra measures",
                    sensorCaption: "Number of measurements in the sensor between two data sending (Default = 1)",
                    threshold: "Alert threshold :",
                    thresholdCaption: "(Default = 800 ppm)",
                    offset: "Offset",
                    offsetCaption: "It corresponds to the minimum concentration level recorded (Default = 400 ppm)"

                },
                plans: {
                    downgrading_sub_alert: "YOU ARE DOWNGRADING YOUR SUBSCRIPTION PLAN",
                    upgrading_sub_alert: "YOU ARE UPGRADING YOUR SUBSCRIPTION PLAN",
                    switching_sub_alert: "By selecting this configuration, your monthly subscription fee will be switched from",
                    switching_sub_alert_to: "By selecting this configuration, your monthly subscription fee will be switched to",
                    notice: "Notice",
                    to: "to",
                    return: "Return",
                    continue: "Continue",
                    select_plan: "Select a subscription plan",
                    select_plan_as_admin: "Select a configuration as Super Admin",
                    current_conf: "Current configuration",
                    current_subs: "Current subscription",
                    diff_conf_subs: "ALERT : The device configuration is not up to date with the subscription",
                    once_every: "Once every",
                    geolocation_period: "geolocation period (while Moving)",
                    keep_alive: "keep alive (Stationary)",
                    subscription_cost: "subscription cost",
                    price: "Price",
                    minutes: "minutes",
                    hours: "hours",
                    per_mounth: " / month",
                    since: "since"
                },
                egiot: {
                    init_battery: "Batteries renewal : "
                },
                tilocker: {
                    unlock_requested: "Unlock",
                    unlock_request_date_time: "Last unlock request",
                    geolocation_upload_period: "Geolocation upload period (in seconds)",
                    geolocation_upload_period_request_date_time: "Last geolocation upload period update",
                    biked_number: "Unlock code",
                    bike_number_max: "The code must be composed of 16 digits in hexadecimal",
                    bike_number_min: "The code must be composed of 16 digits in hexadecimal",
                    bike_search_requested: "Ring",
                    bike_search_request_date_time: "Last ring request",
                    history: "History"
                }
            },
            device: 'Device',
            general: 'General',
            group: 'Group',
            group_name: 'Group name',
            delete_group: 'Delete group',
            subscription: 'Subscription',
            owner: 'Owner',
            from: 'from',
            geofence: 'Geofence',
            browse_location: 'View location',
            browse_subscription: 'View subscription',
            geolocation: 'Devices Location',
            identifier: 'Identifier',
            labels: {
                acknowledgeAlert: 'Acknowledge alert',
                acknowledgeMotion: 'Acknowledge motion',
                acknowledgeRollover: 'Acknowledge rollover',
                checkBattery: 'Check battery',
                configuration: 'Configuration',
                subscription: 'Subscription',
                coordinates: 'Coordinates',
                last_transmission: 'Last transmission',
                latitude: 'Latitude',
                location: 'Location: ',
                location_nbr: 'Location ',
                location_geo: 'Location: ',
                locationsHistory: 'Locations history',
                longitude: 'Longitude',
                accuracy: "Accuracy",
                measures: "Measures",
                speed: "Speed",
                changeIcon: 'Change icon',
                tracking_mode: "Live tracking"
            },
            location: 'location |||| locations',
            name: 'Device |||| Devices',
            no_device_subscription: 'No device does not have a subscription',
            not_found: 'Unknown device id',
            notifications: 'Events',
            properties: 'Properties',
            review_info: 'Device informations review',
            saisir_code: 'Enter the code of the prepaye card',
            select_offer: 'Select an offer below',
            select_offer_type: 'Select an offer type below',
            select_device_information: 'Device information',
            status: {
                unknow: 'Unknown',
            },
            thks_number: 'Fill with the number located on the back of your Tifiz',
            tifiz_number: 'Device identifier',
            to: 'to',
            your_device: 'Your device',
            no_location: 'No location',
            no_subscription: 'No subscription',
            timestamp: 'Date/Time',
            rollover: {
                state: 'State',
                name: 'Rollover',
                detected: 'Rollovered',
                clear: 'Clear'
            },
            activity: {
                still: 'Still',
                moving: 'Moving'
            },
            browse_measure: "View measures history",
            browse_notification_log: "View alertes history",

        },
        products: {
            browse_product: "View product",
            page_titles: {
                products: "Products",
                create_product: "Create a product",
                edit_product: "Edit a product"
            },
            create: "Create a product",
            name: 'Product |||| Products',
            edit: 'Edit a product',
            fields: {
                marketing_name: "Marketing name",
                device_type: "Device type",
                identifier: "Identifier",
                sub_identifier: "SubIdentifier",
                description: "Description",
                version: "Version",
                operator: "Operator",
                image: "Image",
                notification_subjects: "Notification events",
            }
        },
        sms_credit_plans: {

            sections: {
                general: 'General',
                pricing: 'Pricing',
                app_owner_fees: 'Platform Fees',
                stripe: "Stripe"
            },
            fields: {
                viewership_rule: "Visibility",
                root_organization: "Root organization",
                created_by: "Created by",
            },
            viewership_rule: {
                public: "Public",
                private: "Private",
                archived: "Archived"
            },
            add_new_device_sub_plans: "Add a new plan",
            create_success: "The plan has been created successfully, please ask your application administrator to validate the plan.",
            edit_success: "The plan has been successfully modified, please ask your application administrator to validate the plan.",
            name: "SMS credit plans",
            create: "Create an SMS credit plan",
            update: "Modify the SMS credit plan",
            delete: "Delete the SMS credit plan",
            list: "List of SMS credit plan",
            activate: "Activate the SMS credit plan",
            archive: "Archive the SMS credit plan",
            title_fr: "Title in French",
            description_fr: "Description in French",
            title_en: "Title in English",
            description_en: "Description in English",
            title_es: "Title in Spanish",
            description_es: "Description in Spanish",
            root_organization_id: "Root Organization",
            is_default: "Set as default",
            amount: "Amount excl. tax",
            sms_quantity: "SMS Quantity",
            tax_percent: "Tax rate (%)",
            currency: "Currency",
            app_owner_percent_fees: "Platform percentage fees (%)",
            app_owner_fixed_fees: "Platform fixed fees (excl. tax)",
            payment_method_type: "Payment type",
            stripe_price_id: "Stripe Price ID (stripe_price_id)",
            stripe_tax_id: "Stripe Tax ID (stripe_tax_id)"
        },
        device_sub_plans: {
            device_sub_plans: "Device subscription plans",
            sections: {
                general: 'General',
                pricing: 'Pricing',
                app_owner_fees: 'Platform Fees',
                stripe: "Stripe"
            },
            fields: {
                viewership_rule: "Visibility",
                root_organization: "Root organization",
                created_by: "Created by",
            },
            viewership_rule: {
                public: "Public",
                private: "Private",
                archived: "Archived"
            },
            add_new_device_sub_plans: "Add a new plan",
            create_success: "The plan has been created successfully, please ask your application administrator to validate the plan.",
            edit_success: "The plan has been successfully modified, please ask your application administrator to validate the plan.",
            name: "Beacon subscription plan",
            create: "Create a beacon subscription plan",
            update: "Modify the beacon subscription plan",
            delete: "Delete the beacon subscription plan",
            list: "List of beacon subscription plans",
            activate: "Activate the beacon subscription plan",
            archive: "Archive the beacon subscription plan",
            title_fr: "Title in French",
            description_fr: "Description in French",
            title_en: "Title in English",
            description_en: "Description in English",
            title_es: "Title in Spanish",
            description_es: "Description in Spanish",
            root_organization_id: "Root Organization",
            is_default: "Set as default",
            amount: "Amount excl. tax",
            tax_percent: "Tax rate (%)",
            currency: "Currency",
            type: "Type",
            frequency: "Frequency",
            frequency_interval: "Frequency interval",
            trial_period_days: "Number of trial days",
            app_owner_percent_fees: "Platform percentage fees (%)",
            app_owner_fixed_fees: "Platform fixed fees (excl. tax)",
            payment_method_type: "Payment type",
            stripe_price_id: "Stripe Price ID (stripe_price_id)",
            stripe_tax_id: "Stripe Tax ID (stripe_tax_id)"
        },
        vouchers: {
            vouchers: "Vouchers",
            create_success: "The voucher has been created successfully",
            create: "Create Vouchers",
            sections: {
                general: 'General',
                pricing: 'Pricing',
            },
            fields: {
                root_organization: "Root organization",
                created_by: "Created by",
                activated: "Activated",
                plan: "Plan",
                code: "Code",
                batch_number: "Batch number"

            },
            activated: {
                true: "Actived",
                false: "Unused",
            },
            voucher_count: "Number of vouchers",
            price_with_tax: "Price with tax",
            price_without_tax: "Price without tax",
            tax_amount: "Tax amount",
            currency: "Currency",
            root_organization_id: "Root Organization",
            plan_id: "Device subscription plan",
        },
        export: {
            create: 'Data export',
            action: 'Start export',
            fields: {
                device_ids: 'Devices',
                event_type: 'Event',
                start_date: 'Start date',
                end_date: 'End date',
                format: 'Data format'
            },
            export_starting: 'An archive is generating. You will receive a link by email to download it.'
        },
        exportPdf: {
            download: 'Download',
            downloading: 'Downloading ...',
            creationInProgress: 'PDF report creation in progress',
            errors: {
                errorCreation: "An error occurred while creating your PDF report",
                noLocations: 'No locations to download !',
                noMeasures: 'No measures to download !'
            },
            locationsHistoryDocument: {
                title: 'Locations Report',
                created_on: "Created on",
                date: 'Date : ',
                beacon: 'Beacon',
                search: 'Search',
                results: "Results",
                request: 'Request',
                id: "#ID",
                name: "Name",
                start_date: 'Start Date',
                results_start_date: "Date of oldest location",
                end_date: 'End Date',
                results_end_date: "Date of latest location",
                number_of_results: "Number",
                part: "Part",
                itinerary_map: "Itinerary Map",
                table: {
                    title: "History table",
                    location: 'Location',
                    dateTime: 'Date & Time',
                    adress: 'Adress',
                    latitude: 'Latitude',
                    longitude: 'Longitude',
                    accuracy: "Accuracy",
                },
                note: "Note",
                warning_limited_results_size: "Reports generated from the user interface are limited to 1000 locations. Therefore, the results below represent the 1000 positions in the part %{part_number}"

            },
            measuresHistoryDocument: {
                title: 'MEASURES REPORT',
                date: 'Date : ',
                beacon: 'Beacon',
                period: 'Period',
                id: "#ID",
                name: "Name",
                from: 'From',
                to: 'To',
                table: {
                    title: "History table",
                    measure: 'measure',
                    dateTime: 'Date et heure',
                    type: 'Type',
                    value: 'Value',
                }
            },
        },
        fops: {
            name: 'Form of payment |||| Forms of payment',
            error_billing_portal: "An error occured while creating the payment method portal session. Please try again later.",
        },
        geofences: {
            name: 'Geofence |||| Geofences',
            delete: 'Delete geofence',
            create: 'Create geofence',
            edit: 'Edit geofence',
            center: 'Center',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alert',
            fields: {
                radius: 'Radius',
                address: 'Address',
                on_entry: 'on entry',
                on_exit: 'on exit',
                active: 'Active',
            },
            draw: {
                save_modifications: 'Save modifications',
                cancel_modifications: 'Cancel modifications',
                select_geofence: 'Drag handles or markers to edit features',
                click_cancel: 'Click cancel to undo changes',
            },
            device_search: 'Search a device',
            enter_address: 'Enter an address',
            no_device_selected: 'You must select a device',
            no_center_defined: "You haven't defined a center",
        },
        humidity: {
            head: 'Humidity',
            last: 'Value',
            date: 'Last check',
        },
        battery: {
            head: 'Battery level',
        },
        pressure: {
            head: 'Pressure',
        },
        luminosity: {
            head: 'Ambiant light',
        },
        light_status: {
            head: 'Light Status',
        },
        formaldehyde: {
            head: 'Formaldehyde',
        },
        tvoc: {
            head: 'Tvoc (Total volatile organic compounds)',
        },
        noise: {
            head: 'Noise',
        },
        ammonia: {
            head: 'Ammonia',
        },
        mox: {
            head: 'Mox',
        },
        aqi: {
            head: 'Air Quality Index',
            help_legend: {
                head_ref_levels: "Refrence Levels",
                head_air_info: "Air Information",
                head_tvoc_mg_m3: "TVOC (mg/m3)",
                head_air_quality: "Air Quality",
                level1: "Level 1",
                level2: "Level 2",
                level3: "Level 3",
                level4: "Level 4",
                level5: "Level 5",
                air_info_level1: "Clean Hygienic Air (Target Value)",
                air_info_level2: "Good Air Quality (if no threshold is exceeded)",
                air_info_level3: "Noticeable Comfort Concerns (Not recommended for exposure > 12 months)",
                air_info_level4: "Significant Comfort Issues (Not recommended for exposure > 1 months)",
                air_info_level5: "Unacceptable conditions (Not recommended)",
                air_quality_level1: "Very Good",
                air_quality_level2: "Good",
                air_quality_level3: "Medium",
                air_quality_level4: "Poor",
                air_quality_level5: "Bad",

            }
        },
        pm1: {
            head: 'PM1 Particles',
        },
        pm2p5: {
            head: 'PM2.5 Particles',
        },
        pm10: {
            head: 'PM10 Particles',
        },
        benzen: {
            head: 'Benzen',
        },
        co2: {
            head: 'CO2',
            head_min: 'Co2',
            treshold: 'Treshold',
            max: 'Maximum',
            min: 'Minimum',
            avg: 'Average',

        },
        internal_error: 'Internal error',
        lang: 'Language',
        door: {
            head: 'Lid',
            last: 'Value',
            date: 'Last check',
            open: 'Open',
            closed: 'Closed',
        },
        water_leak: {
            head: 'Water leak',
            on: 'On',
            off: 'Off',
        },
        presence: {
            head: 'Presence',
        },
        battery_status: {
            head: 'Battery status',
        },
        button: {
            head: 'Button',
        },
        motion: {
            head: 'Motion',
        },
        location: {
            head: 'Location'
        },
        locations: {
            name: 'Location |||| Locations',
            labels: {
                addDevice: 'Add new device',
                devicesPositions: 'Devices Location',
                hideMenu: 'Menu',
                exportData: 'Export locations',
                addLocation: 'Add a location',
                delete: "Delete history",
                location: 'Location',
                search: 'Search a device',
                viewHistory: "View history"
            },
            fields: {
                name: "Name",
                device_id: "Device",
                timestamp: "Date",
                latitude: "Latitude",
                longitude: "Longitude",
                altitude: "Altitude",
                speed: "Speed",
                accuracy: "Accuracy (meters)",
                formatted_address: "Formatted address",
                country: "Country",
                state: "State",
                city: "City",
                postcode: "Postcode",
            },
            locationNone: 'None',
            location5: 'Last 5 locations',
            location25: 'Last 25 locations',
            location50: 'Last 50 locations',
            location100: 'Last 100 locations',
            locationYesterday: 'Yesterday',
            locationToday: 'Today',
            customized: "Customized",
            searchOption: "Search option",
            accuracy: {
                all: "All",
                lessThan20km: "Less than 20km",
                lessThan10km: "Less than  10km",
                lessThan5km: "Less than  5km",
                lessThan1km: "Less than  1km",
                lessThan500m: "Less than  500m",
                lessThan100m: "Less than  100m",
                lessThan50m: "Less than  50m",
            }
        },
        map: {
            road: 'Road',
            satellite: 'Satellite',
            routing: 'Itinerary',
            routing_mode: {
                driving: 'Driving',
                cycling: 'Cycling',
                walking: 'Walking',
                boat: 'Boat',
                no_routing: 'No routing'
            },
            clustring: "Clustring"
        },
        markerpopup: 'Display on click',
        notification_logs: {
            name: "Alerts log",
            nacknowledged_notifications: "Unacknowleged alerts",
            timestamp: 'Date',
            description: 'Description',
            acknowledge: "Acknowledge",
            nacknowledge: "Undo acknowledge",
            acknowledged: "Acknowleged",
            nacknowledged: "Not acknowleged",
            request_success_acknowledged: "Alert acknowleged successfully",
            request_success_nacknowledged: "Alert unacknowleged successfully",
            request_error_acknowledged: "Request error",
            request_error_nacknowledged: "Request error",
            notifLog5: 'Last 5 alerts',
            notifLog25: 'Last 25 alerts',
            notifLog50: 'Last 50 alerts',
            notifLog100: 'Last 100 alerts',
            notifLogYesterday: 'Yesterday',
            notifLogToday: 'Today',
            customized: "Customized",

        },
        notification_log: {
            head: "Alerts log",
        },
        notifications: {
            info_about_destination_verification: {
                title: "You will need to check the emails or phone numbers you enter as the destination for your alert",
                message1: 'You will receive a code on your email or by sms, which you must enter on the notification page by clicking on "verification" at the top of the page.',
                message2: "This is for security reasons, as well as to ensure that the alert is properly operated."
            },
            browse_notification: "View alert",
            button_press: 'Button press',
            button_double_pressed: 'Button press - Double click',
            button_held: 'Button press - Long click',
            button_pressed: 'Button press - Simple click',
            shock_detected: 'Shock detected',
            motion_detected: 'Motion detected',
            light_status_from_on_to_off_detected: "Light status changes from ON to OFF",
            light_status_from_off_to_on_detected: "Light status changes from OFF to ON",
            rollover_detected: 'Rollover detected',
            door_opened: 'Door opened',
            water_leak_detected: 'Water leak detected',
            temperature_threshold_reached: 'Temperature threshold reached',
            co2_threshold_reached: 'Co2 concentration threshold reached',
            humidity_threshold_reached: 'Humidity threshold reached',
            battery_status_bad: 'Bad battery status',
            battery_low: 'Low battery level',
            address_country_changed: 'Country change',
            address_state_changed: 'State change',
            address_city_changed: 'City change',
            high_g_thresholds_reached: "High G thresholds reached",
            channel: 'Channel',
            create: 'Create alert',
            edit: "Edit alert",
            delete: "Delete alert",
            enter_phone_number: 'Enter a phone number',
            email: 'Email',
            subject: 'Event',
            fields: {
                language: "Language",
                english: "English",
                french: "French",
                spanish: "Spanish",
                timezone: "Timezone",
                device_id: 'Device',
                channel: 'Channel',
                created_by: "Created by",
                subject: 'Event',
                destinations: 'Recipients',
                name: "Alert name",
                oneDevice: "One device",
                applyOn: "Apply on",
                applyOnOneDevice: "One device",
                applyOnGroupOfDevices: "Group of devices",
                applyOnGroupOfOrganizations: "Group of organizations",
                device_ids: "Devices",
                organization_ids: "Organizations",
                active: "Active",
                geofence_input_draw_on_map: "Click below if you wish to draw the virtual barrier on a map",
                geofence_input_method: "Input method for the geofence",
                geofence_input_method_with_map: "Draw on map",
                geofence_input_method_without_map: "Enter coordinates manually",
                geofence_latitude: "Geofence latitude",
                geofence_longitude: "Geofence longitude",
                geofence_radius: "Geofence radius",
                geofence_formatted_address: "Geofence address",
                geofence_on_entry: "Active on entry",
                geofence_on_exit: "Active on exit",
                high_g_peak_threshold: "Peak threshold",
                high_g_average_threshold: "Average threshold",
                high_g_duration_threshold: "Duration threshold",
                battery_threshold_value: "Threshold value",
                battery_threshold_unit: "Threshold unit",
                co2_threshold_value: "Threshold value",
                co2_threshold_unit: "Threshold unit",
                humidity_threshold_value: "Threshold value",
                humidity_threshold_unit: "Threshold unit",
                temperature_threshold_value: "Threshold value",
                temperature_threshold_unit: "Threshold unit",
                calendar: {
                    calendar: "Calendar",
                    monday: "Monday",
                    tuesday: "Tuesday",
                    wednesday: "Wednesday",
                    thursday: "Thursday",
                    friday: "Friday",
                    saturday: "Saturday",
                    sunday: "Sunday",
                    weekdays: "Weekdays",
                    weekend: "Weekend",
                    alldays: "Alldays",
                    clear: "Clear",
                }
            },
            geofence_crossed: 'Geofence Crossed',
            message: 'message',
            name: 'Alert |||| Alerts',
            option: 'Option',
            update: 'Edit',
            notifications_redirect: "You will be redirected to the alerts page...",
            sms_credit: "Sms Credit",
            sms_credit_success: "Your purchase has been successfully processed",
            cannot_edit_notification_on_mobile_title: "Editing Alert Not Available on Mobile",
            cannot_edit_notification_on_mobile_message: "This alert uses a schedule with multiple time intervals on the same day. Please make this modification from a computer or a device with a larger screen."

        },
        integrations: {
            empty_jd_integrations: "The integration of your organization with the john deere platform has not yet been created.",
            create_new_jd_integration: "Create an integration with John Deere",
            click_below_to_create_new_jd_integration: "Click below to start integration with your john deere operation center account",
            empty_tibib_integrations: "The integration of your organization with Tibib platform has not yet been created.",
            create_new_tibib_integration: "Create an integration with Tibib",
            click_below_to_create_new_tibib_integration: "Click below to begin integration with the Tibib platform",
            browse_jd_integrations: "Go to John Deere integrations",
            browse_tibib_integrations: "Go to Tibib Live integrations",
            list_sections: {
                tibib: "Tibib Live",
                johndeere: "John Deere Operation Center"

            },
            fields: {
                organization: "Organization",
                jdOrganization: "John Deere organization ID",
                email: 'Email',
                name: "Name",
                description: "Description",
            },
            name: 'Integration |||| Integrations',
            info_about_integration_john_deere: {
                title1: "What it's about?",
                message1_1: "This feature links devices that are on an organization to your John Deere Operation Center.",
                message1_2: "This allows you to view the positions of your devices directly on the operation center map, next to your equipments.",
                title2: "How does it work?",
                message2: "All you have to do is select the organization you wish to link to Tibib Live, then click on the “Save” button.",
            },
            info_about_integration_tibib: {
                title: "What it's about?",
                message1: "This feature links devices that are on an organization to the Tibib Live platform.",
                title2: "How does it work?",
                message2: "All you have to do is select the organization you wish to link to Tibib Live, then click on the “Save” button.",
            }

        },
        organization:
        {
            description: 'Description',
            name: 'Name',
            organization: 'Organization',
            parent: 'Parent organization',
            root: 'Root organization',
            update: 'Edit',
            creation_success: "Organization created successfully",
            update_success: "Organization updated successfully",
            site_draw_on_map: "Click below to draw the perimeter around the organization's site:",
            site_formatted_address: "Site address",
            site_boundary_radius: "Boundary radius",
            site_latitude: "Latitude",
            site_longitude: "Longitude",
        },
        root_organization: {
            sections: {
                instructions: "Instructions",
                general: 'General',
                appConf: 'Application Configuration',
                theme: 'Theme',
                invoicing: "Invoicing",
                data_storage: "Data Storage",
                notifications: "Notifications"

            },
            instructions: {
                intro: "To create a root organization, make sure you have followed the required steps above:",
                required_steps_1: "1. In mandrill, Ticatag must authenticate the customer's domain in order to send emails with the customer's address as the sender.",
                required_steps_1_1: "1.1 Log on to mandrill :https://mandrillapp.com/",
                required_steps_1_2: "1.2 Click on 'Settings' in the left-hand menu",
                required_steps_1_3: "1.3 Click on 'Domains' in the menu at the top of the page, then 'Sending Domains'",
                required_steps_1_4: "1.4 Enter the customer's domain name (e.g. timcod.com) in the 'Add' bar on the left of the page, then click on 'Add'",
                required_steps_1_5: "1.5 Enter the customer's domain name (e.g. timcod.com) in the search bar this time, then click on the magnifying glass to search",
                required_steps_1_6: "1.6 In this newly created line, click on 'View details' in the 'DKIM Settings' column",
                required_steps_1_7: "1.7 You'll find the instructions to send to the customer with the DKIM values. You can also take a screenshot and send it to the customer to complete the procedure", required_steps_2: "2. Dans OVH, une entrée de type cname doit être créée pour rediriger l'url de l'application vers la distribution cloud front du site web :",
                required_steps_2: "2. In OVH, an entry of type cname must be created to redirect the url of the application to the cloud front distribution of the website:",
                required_steps_2_1: "2.1 Connect to OVH: 'https://www.ovh.com/manager/#/web/domain/ticatag.com/zone'",
                required_steps_2_2: "2.2 You must be on the 'Web Cloud' -> 'Zone Dns' page",
                required_steps_2_3: "2.3 Click on 'Add entry' in the menu",
                required_steps_2_4: "2.4 Select DNS field type: 'CNAME'",
                required_steps_2_5: "2.5 Enter the customer's sub-domain (e.g. ppd1-timcod-connect)",
                required_steps_2_6: "2.6 Enter the Target which is the url of the cloudfront distribution (e.g. d2bpq0m9drdksq.cloudfront.net.)",
                required_steps_2_7: "2.7 Click on 'Next', then 'Validate'",
                required_steps_3: "3. in the infrastructure code, add a domain name line for the root organization:",
                required_steps_3_1: "3.1 Go to the code folder",
                required_steps_3_2: "3.2 Go to file: 'cdk/lib/persistent-stack/persistent-stack.ts' ",
                required_steps_3_3: "3.3 In the connect cloud front distribution creation line: 'this.createCloudFrontDistribution(........)'",
                required_steps_3_4: "3.4 In the last parameter of the list function, add the url of the application (e.g. `${props.prefix}-timcod-connect.ticatag.com`)",
                required_steps_3_5: "3.5 In the terminal, in the /cdk: folder",
                required_steps_3_6: "-----> To view changes to the stack, run the command: APP_PREFIX=ppd1 yarn cdk diff --profile ...",
                required_steps_3_7: "-----> To deploy, execute the command: APP_PREFIX=ppd1 yarn cdk deploy --profile ....",
                required_steps_4: "4. in the database, add the redirection address:",
                required_steps_5: "5. Add firebase analytics:",
                required_steps_5_1: "Go to https://console.firebase.google.com/",
                required_steps_5_2: "Create a project",
                required_steps_5_3: "Create a web app",
                required_steps_5_4: "Save the keys to create root organization",
            },
            app_title: 'Application Title',
            contact_page_url: "Contact page URL",
            admin_email: "Admin email",
            name: 'Name',
            company_url: 'Company URL',
            app_url1: 'Application URL 1',
            app_url2: 'Application URL 2',
            app_url3: 'Application URL 3',
            firebase_api_key: 'Firebase API Key',
            firebase_auth_domain: 'Firebase Auth Domain',
            firebase_project_id: 'Firebase Project ID',
            firebase_storage_bucket: 'Firebase Storage Bucket',
            firebase_messaging_sender_id: 'Firebase Messaging Sender ID',
            firebase_app_id: 'Firebase App ID',
            firebase_measurement_id: 'Firebase Measurement ID',
            theme_light_primary_main: 'Light Theme Primary Main Color',
            theme_light_primary_dark: 'Light Theme Primary Dark Color',
            theme_light_primary_light1: 'Light Theme Primary Light Color 1',
            theme_light_primary_light2: 'Light Theme Primary Light Color 2',
            theme_light_primary_light3: 'Light Theme Primary Light Color 3',
            theme_light_background_default: 'Light Theme Default Background',
            theme_light_background_paper: 'Light Theme Paper Background',
            theme_light_background_appframe: 'Light Theme Appframe Background',
            theme_dark_primary_main: 'Dark Theme Primary Main Color',
            theme_dark_primary_dark: 'Dark Theme Primary Dark Color',
            theme_dark_primary_light1: 'Dark Theme Primary Light Color 1',
            theme_dark_primary_light2: 'Dark Theme Primary Light Color 2',
            theme_dark_primary_light3: 'Dark Theme Primary Light Color 3',
            theme_dark_background_default: 'Dark Theme Default Background',
            theme_dark_background_paper: 'Dark Theme Paper Background',
            theme_dark_background_appframe: 'Dark Theme Appframe Background',
            background_image1: 'Background Image 1',
            background_image2: 'Background Image 2',
            background_image3: 'Background Image 3',
            background_image4: 'Background Image 4',
            background_image5: 'Background Image 5',
            background_image6: 'Background Image 6',
            connect_header_logo: 'Connect header logo',
            connect_favicon_logo: 'Connect favicon logo',
            connect_icon96x96_png: 'Connect icon96x96 png',
            connect_icon180x180_png: 'Connect icon180x180 png',
            connect_icon192x192_png: 'Connect icon192x192 png',
            connect_icon512x512_png: 'Connect icon512x512 png',
            auth_page_logo: "Authentication page logo",
            invoice_number_prefix: 'Invoice Number Prefix',
            invoice_issuer_name: 'Invoice Issuer Name',
            invoice_issuer_address: 'Invoice Issuer Address (ex. 5 Avenue Anatole - 75007 - Paris - France )',
            invoice_issuer_phone_number: 'Invoice Issuer Phone Number',
            invoice_issuer_email: 'Invoice Issuer Email',
            invoice_issuer_web_site: 'Invoice Issuer Website',
            invoice_logo: 'Invoice Logo',
            invoice_issuer_description1: 'Invoice Issuer Description 1  (siret ex. "Siret: 123456789")',
            invoice_issuer_description2: 'Invoice Issuer Description 2',
            invoice_issuer_description3: 'Invoice Issuer Description 3',
            invoice_footer_description1: 'Invoice Footer Description 1',
            invoice_footer_description2: 'Invoice Footer Description 2',
            invoice_footer_description3: 'Invoice Footer Description 3',
            invoice_issuer_vat_number: 'VAT number',
            subscription_plans_currency: 'Currency of subscription plans',
            platform_fee_tax_percent: 'VAT percent applied to platform service charges',
            events_ttl_months: "Month events expire",
            notification_sender_email: "Email address of the sender",
            notification_sender_name: "Sender name",
            notification_email_signature1: "Signature 1",
            notification_email_signature2: "Signature 2",
            notification_email_signature3: "Signature 3",
            notification_email_logo: "Email logo",
        },
        organizations: {
            page_titles: {
                organizations: "Organizations",
                create: "Create an organization",
                edit: "Edit an organization"
            },
            create: "Create organization",
            name: 'Organizations',
            edit: 'Edit organization'
        },
        root_organizations: {
            browse_root_organization: "View root organization",
            create: "Create root organization",
            name: 'Root organizations',
            edit: 'Edit root organization'
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            date: 'Date',
            description: 'Description',
            number: 'Reference',
            price: 'Price',
            download: 'Download',
            click_button_bellow_to_download_you_invoice_number: "Click the button below to download your invoice : %{invoice_number}",
            period: "Period",
            currency: "Currency",
            product_label: 'Label',
            product_description1: 'Description 1',
            product_description2: 'Description 2',
            price_tax_included: "Price (incl all taxes)",
            tax_amount: "Tax amount",
            customer: "Customer"
        },
        app_owner_invoices: {
            fields: {
                invoice_number: "Invoice number",
                creation_date: "Creation date",
                product_label: "Label",
                root_organization: "Root organization",
                period: "Period",
                total_amount: "Total amount (incl. Tax)",
                invoices_details_total_amount: "Amount paid by client(incl.taxes)",
                invoices_details_total_amount_without_tax: "Amount paid by client(excl.taxes)",
                invoices_details_total_net_root_org: "Amount for Root Organization(excl.taxes)",
                invoices_details_total_fee_amount_without_tax: "Amount of platform fee(excl.taxes)",
                status: "Status",
                payment_date: "Payment Date"
            },
            status: {
                draft: "Draft",
                validated: "Validated",
                paid: "Paid"
            },
            name: 'Plateform Invoice |||| Plateform Invoices',
            download: 'Download',
            validate: "Validate",
            pay: "Pay"
        },
        product: {
            name: 'Product Name',
            release: 'Release Date',
            versionNumber: 'Version Number',
            patch: 'Patch Number',
        },
        profile: 'User Profile',
        role: {
            owner: 'Owner',
            member: 'Member',
            admin: 'Admin',
            superadmin: 'Super Admin',
        },
        shock: {
            head: 'Shock',
            last: 'Value',
            date: 'Last check',
            detected: 'Detected',
            clear: 'Clear'
        },
        measures: {
            name: 'Measure |||| Measures',
            sensorNone: 'None',
            sensor: "Sensor",
            no_measures_of_this_type: "No measures of this type have been pulled by the device",
            displayedMeasures: "Displayed measures",
            sensor5: 'Last 5 measures',
            sensor25: 'Last 25 measures',
            sensor50: 'Last 50 measures',
            sensor100: 'Last 100 measures',
            sensorYesterday: 'Yesterday',
            sensorToday: 'Today',
            customized: "Customized",
            searchOption: "Search option",
            temphum: 'Temperature/Humidity',
            timestamp: 'Date',
            history: 'See measures history',
            status: 'Status',
            date: 'Date',
            water_leak_status_off: 'Off',
            water_leak_status_on: 'On',
            door_status_open: 'Open',
            door_status_closed: 'Closed',
            shock_status_clear: 'Not Detected',
            shock_status_detected: 'Detected',
            presence_status_detected: 'Detected',
            presence_status_none: 'Undetected',
            battery_status_status_good: 'Good',
            battery_status_status_bad: 'Bad',
            light_status_status_on: 'On',
            light_status_status_off: 'Off',
            button_status_click: 'Click',
            button_status_double_click: 'Double click',
            button_status_hold: 'Hold',
            motion_status_still: 'Fixed',
            motion_status_moving: 'Moving',
        },
        plans: {
            name: 'Plan |||| Plans',
        },
        subscriptions: {
            accept_tc: 'Please accept sales condition',
            add_card_success: 'Form of payment saved',
            add_card_error: 'Please verify your paiement details',
            add_fop_error: 'An error has occured during credit card registration process, please retry',
            description: "You will be charged every month on your credit card",
            charged_now: 'Charged now : %{amount} %{currency_symbol}',
            enter_voucher: 'Please, enter the number written on your voucher',
            fields: {
                active: 'Active',
                device: {
                    name: 'Device name',
                },
                end_date: 'End date',
                plan: {
                    description: 'Offer',
                    type: 'Type',
                    title: 'Title',
                },
                start_date: 'Start date',
                status: 'Status',
            },
            labels: {
                active: 'Active',
            },
            statuses: {
                active: 'Active',
                unpaid: 'Unpaid',
                past: 'Past',
                canceled: 'Canceled',
                unknown: "Unknown"
            },
            states: {
                onGoing: 'On going',
                comingNext: 'Coming next',
                unpaid: 'Waiting for next payment attempt',
            },
            name: 'Subscription |||| Subscriptions',
            create: "Create subscription",
            add_subscription: 'Add subscription',
            add_subscription_as_super_admin_tooltip: "Add subscription as Super Admin",
            add_subscription_as_super_admin: "S.A : Add subscription",
            cancel_subscription: 'Cancel subscription',
            cancel_subscription_confirmation: 'Are you sure you want to cancel your subscription?',
            subscription_canceled: 'Subscription canceled',
            no_subscription_found: 'No subscription associated to the device',
            subscription_already_attached_to_device: "Subscription already attached to your device",
            subscription_success: 'Your subscription has been registered',
            subscription_creating: 'Your subscription is in progress...',
            subscription_creation_error: "An error occurred while creating your subscription!  You will be redirected to the support contact page... ",
            subscription_recognizing_error: "An error occurred while getting subscriptions attached to this device!  Please contact us...",
            no_selected_plan: 'No plan selected',
            postpaid_name: 'Monthly plan',
            prepaid: 'Prepaid',
            prepaid_name: 'Prepaid plan - %{quantity} month',
            prepaid_offer: 'Online prepaid',
            prepaid_offer_description: 'Purchase a prepaid plan',
            selected_offer: 'Selected offer',
            tc:
                'I accept <a href="http://mytifiz.com/legal/cgv" target="_blank"> the terms of use</a> and related subscription terms above.',
            tc_postpaid:
                "You will be charged %{amount} %{currency_symbol} monthly until you decide to terminate your subscription. Amounts already charged will not be refunded upon cancellation. A cancellation fee will be applied if you cancel within the first twelve months. Your payment information is encrypted and secure. All amounts are shown in %{currency_code}.",
            tc_prepaid:
                'You will be charged %{amount} %{currency_symbol}. Amounts already deducted will not be refunded. At the end of the subscription period, your subscription will not be automatically renewed. Your payment details are encrypted and secure. All amounts are indicated in %{currency_code}.',
            voucher: 'Voucher',
            voucher_description: 'When you have a voucher card (code) to activate the device',
            voucher_already_burnt: 'Code already used',
            voucher_code: 'Number',
            voucher_name: 'Voucher - %{quantity} month',
            voucher_not_found: 'Unknown code',
            voucher_title: 'Voucher code : %{voucher_code}',
            voucher_length_must_be_gt_10: "The voucher code must be greater than 10 digits"
        },
        temperature: {
            head: 'Temperature',
            value: 'Last temperature',
            date: 'Date',
        },
        theme: 'Theme',
        timechart: 'Temperature history',
        time_range: 'Zoom',
        title: {
            configuration: {
                list: 'Configuration',
            },
            devices: {
                list: 'Devices',
                editer: 'Device Edit',
                create: 'Device Create',
            },
            geofences: {
                list: 'Geofences',
                editer: 'Geofence Edit',
                create: 'Geofence create',
            },
            locations: {
                list: 'Locations',
                editer: 'Location History',
            },
            notifications: {
                list: 'Notifications',
                editer: 'Notification Edit',
                create: 'Notification Create',
            },
            organizations: {
                list: 'Organizations',
                editer: 'Organization Edit',
                create: 'Organization Create',
            },
            measures: {
                list: 'Sensors',
                history: 'Sensors Histories',
                create: 'Sensor Create',
            },
            users: {
                list: 'Users',
                editer: 'User Edit',
                create: 'User Create',
            },
        },
        upload: 'Choose your file ...',
        account: {
            edit: 'Profile',
            personal_information: "Personal Information",

        },
        users: {
            invite_action: 'Invite user',
            invite_success: "The user has been invited successfully, he will receive an email to follow the process of creating his account.",
            creation_success: "User has been created successfully",
            confirm_edit_organization_message: "If the user's organization changes, the devices he owns will be transferred to the new organization.",
            create: 'Create user',
            edit: 'Edit user',
            delete: 'Remove the user from your organization',
            email: 'Email',
            cemail: 'Confirm email',
            password: 'Password',
            cpassword: 'Confirm password',
            company_name: 'Company',
            customer: 'Customer',
            first_name: 'First Name',
            phoneNumber: 'Phone number',
            birth_date: 'Birth date',
            invite: 'Send Invitation',
            last_name: 'Last Name',
            name: 'User |||| Users',
            role: 'Role',
            update: 'Edit',
            user: 'User',
            users: 'Users',
            language: 'Language',
            profile: 'Profile',
            passwords_not_match: 'The passwords don\'t match',
            invalid_password: 'The password must be at least 6 characters long',
            password_changed: 'Password changed',
            change_my_password: "Change my password",
            email_changed: 'Email updated',
            emails_not_match: 'The emails don\'t match',
            invalid_email: 'Invalid email',
            lang: {
                fr: 'French',
                en: 'English',
            }
        },
        contact: {
            name: 'Contact',
            delete: 'Delete geofence',
            create: 'Create geofence',
            edit: 'Edit geofence',
            center: 'Center',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alert',
            fields: {
                radius: 'Radius',
                address: 'Address',
                on_entry: 'on entry',
                on_exit: 'on exit',
                active: 'Active',
            },
            draw: {
                save_modifications: 'Save modifications',
                cancel_modifications: 'Cancel modifications',
                select_geofence: 'Drag handles or markers to edit features',
                click_cancel: 'Click cancel to undo changes',
            },
            device_search: 'Search a device',
            enter_address: 'Enter an address',
            no_device_selected: 'You must select a device',
            no_center_defined: "You haven't defined a center",
        },
        help: {
            name: 'Help',
            faq: "FAQ",
            delete: 'Delete geofence',
            create: 'Create geofence',
            edit: 'Edit geofence',
            center: 'Center',
            latitude: 'Latitude',
            longitude: 'Longitude',
            trigger: 'Alert',
            fields: {
                radius: 'Radius',
                address: 'Address',
                on_entry: 'on entry',
                on_exit: 'on exit',
                active: 'Active',
            },
            draw: {
                save_modifications: 'Save modifications',
                cancel_modifications: 'Cancel modifications',
                select_geofence: 'Drag handles or markers to edit features',
                click_cancel: 'Click cancel to undo changes',
            },
            device_search: 'Search a device',
            enter_address: 'Enter an address',
            no_device_selected: 'You must select a device',
            no_center_defined: "You haven't defined a center",
        },
        hooks: {
            browse_hook: "View webhook",
            create: 'Create webhook',
            edit: 'Edit webhook',
            name: 'Webhook |||| Webhooks',
            location_changed: 'Location changed',
            new_location: 'New location',
            state_changed: 'State changed',
            button_pressed: 'Button press',
            button_pressed_click: 'Button press - Click',
            button_pressed_double_click: 'Button press - Double click',
            button_pressed_hold: 'Button press - Long click',
            new_co2: "Co2 rate - New measure",
            new_temperature: 'Temperature - New measure',
            new_humidity: 'Humidity - New measure',
            new_door: 'Door - New status',
            new_water_leak: 'Water leak - New status',
            new_shock: 'Shock - New status',
            new_rollover: 'Rollover - New status',
            new_battery_level: 'Battery level - New measure',
            new_battery_status: 'Battery - New status',
            new_motion: 'Motion - New status',
            new_notification_log: "Alert log - New Alert",
            new_pressure: "Pressure - New measure",
            new_luminosity: "Luminosity - New measure",
            new_light_status: "Light status - New measure",
            new_aqi: "Air quality index - New measure",
            new_mox: "Mox - New measure",
            new_alarm: "Alarm - New alarm",
            new_presence: "Presence - New status",
            new_total_odometer: "Total odometer - New measure",
            new_high_g: "High G - New measure",
            new_formaldehyde: "Formaldehyde - New measure",
            new_pm10: "PM10 Particles - New measure",
            new_pm2p5: "PM2P5 Particles - New measure",
            new_pm1: "PM1 Particles - New measure",
            new_tvoc: "TVOC - New measure",
            new_ammonia: "Ammonia- New measure",
            new_benzen: "Benzen - New measure",
            new_noise: "Noise - New measure",
            device: {
                warning: 'Leave empty to apply on all devices'
            },
            version: {
                warning: 'Only webhook v3 creation is allowed',
            },
            fields: {
                name: "Webhook name",
                target_url: "Url",
                events: "Events",
                version: "Version",
                active: 'Active',
                device: 'Device',
                organization: "Organization",
                applyOn: "Apply on",
                applyOnOneDevice: "Device",
                applyOnOneOrganization: "Organization",
                auth: "Authentication",
                auth_type: "Type",
                auth_username: 'Username',
                auth_password: 'Password',
            },
            info_about_hooks: {
                title: "What's new in V3:",
                message1: "GPS locations can be sent back via two events:",
                message2: "- New location: this event sends you the location of the device at each message",
                message3: "- Location changed: this event sends you the location of the device only if it is different from the previous one (with a accuracy of 20 meters)"
            }
        }
    },
}
